.otp_box {
  width: 500px !important;
  justify-content: center !important;
}

.otp_box input {
  width: 60px !important;
  height: 68px !important;
}

.multi-range-slider {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 10px 10px !important;
}

.multi-range-slider .ruler {
  display: none !important;
}

.register_box {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.76);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.8px);
  -webkit-backdrop-filter: blur(10.8px);
  border: 1px solid rgba(255, 255, 255, 0.71);
}

.accordion-button.collapsed {
  background: #fff;
}

.accordion-button:focus {
  border-color: #fff;
  outline: 0;
  box-shadow: none;
}

.accordion-item {
  background: #fff;
}

.question-toggle-next,
.question-toggle-prev,
.apply-btn,
.cancel-btn {
  background-color: #ffffff !important;
  border: none !important;
  color: #7086ff !important;
  /* box-shadow: 0px 0px 5px #ddd; */
  cursor: pointer;
  border: 1px solid #ddd !important;
}

.cancel-btn {
  color: crimson !important;
}

.question-toggle-next:disabled,
.question-toggle-prev:disabled {
  cursor: not-allowed !important;
  pointer-events: all;
}

.form-check-input:checked {
  background-color: #7086ff !important;
  border-color: #7086ff !important;
}

.w-70 {
  width: 70%;
}

.group-referent-overflow {
  max-height: 200px;
  min-height: 200px;
  height: 100%;
  overflow-y: scroll;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.profile-overflow {
  max-height: 285px;
  height: 100%;
  overflow-y: scroll;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.comments {
  min-height: 222px;
  max-height: 222px;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.organizer-voter-overflow {
  min-height: 250px;
  max-height: 250px;
  height: 100%;
  overflow-y: scroll;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.message-overflow {
  max-height: 250px;
  height: 100%;
  overflow-y: scroll;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.payment-detail {
  border: 2px solid #ccc;
  border-radius: 4px;
}

.text_light {
  color: #fff;
}

/* login page  */
.login-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.login-buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  border-radius: 25px;
  color: #fff;
  background-color: #f5f5f5;
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
}

.login-buttons a:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
}

.login-buttons a:hover {
  background-color: #fff;
}

.login-buttons a:hover:before {
  left: 100%;
}

/* .login-buttons img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
} */

.google-login-button {
  background-color: #db4437 !important;
}

.facebook-login-button {
  background-color: #1877f2 !important;
}

.apple-login-button {
  background-color: #000 !important;
}

.fullscreen-modal .modal-dialog {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.fullscreen-modal .modal-content {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}