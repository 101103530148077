/* comment section */

.comment-card {
    display: flex;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.comment-content {
    flex: 1;
}

.comment-author {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.comment-text {
    margin: 5px 0;
    font-size: 14px;
}

.comment-actions {
    margin-top: 10px;
}

.like-button {
    background-color: #eee;
    border: none;
    padding: 5px 10px;
    font-size: 12px;
    color: #888;
    cursor: pointer;
}

.reply-button {
    background-color: #eee;
    border: none;
    padding: 5px 10px;
    font-size: 12px;
    color: #888;
    cursor: pointer;
    margin-left: 10px;
}

.reply-form {
    margin-top: 10px;
}

.reply-textarea {
    width: 100%;
    resize: vertical;
    padding: 5px;
}

.reply-submit-button {
    background-color: #eee;
    border: none;
    padding: 5px 10px;
    font-size: 12px;
    color: #888;
    cursor: pointer;
    margin-top: 5px;
}

.replies {
    margin-top: 10px;
}

.replies-heading {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.reply {
    margin-bottom: 10px;
}

.reply-author {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
}

.reply-text {
    margin: 5px 0;
    font-size: 12px;
}

.reply-likes {
    margin: 5px 0;
    font-size: 12px;
    color: #888;
}